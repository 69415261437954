
















































































import Vue, { VueConstructor } from 'vue';
import AdminUserService from '@/services/admin/AdminUserService';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { MetaInfo } from 'vue-meta';

class hostInvoiceDto {
  invoiceDate!: Date;
  amount!: number;
  hostInvoiceLineItemCount!: number;
  invoiceNumber!: string;
  createdAt!: Date;
}

class AdminHostDetailDto {
  id!: string;
  createdAt!: Date;
  lastLoginAt!: Date;
  hostInvoices!: hostInvoiceDto[];
  displayName!: string;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  props: {
    hostUserId: {
      required: true,
      type: String,
    },
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminHostDetail.meta.title', { name: this.host.displayName }).toString(),
    };
  },
  data() {
    return {
      ready: false,
      host: {} as AdminHostDetailDto,
      loading: false,
      invoiceIdsInUse: [] as Date[],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    invoiceInUse(date: Date): boolean {
      return this.invoiceIdsInUse.indexOf(date) >= 0;
    },

    setBreadCrumb(): void {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { to: { name: 'AdminOwnerIndex' }, translationKey: 'pages.admin.AdminHostDetail.breadcrumb.hosts' },
        { text: this.host.displayName },
      ]);
    },

    recreateInvoice(date: string): void {
      this.loading = true;
      AdminUserService.recreateInvoices(this.hostUserId, date).then(() => {
        this.loadData();
      });
    },
    recreateAllInvoices(): void {
      this.loading = true;
      AdminUserService.recreateAllInvoices(this.hostUserId).then(() => {
        this.loadData();
      });
    },

    loadData() {
      this.loading = true;
      AdminUserService.getHost(this.hostUserId).then((resp) => {
        this.loading = false;
        this.host = resp.value;
        this.host.hostInvoices = resp.value.hostInvoices.map((hi: any) => {
          return {
            invoiceDate: new Date(hi.invoiceDate),
            createdAt: new Date(hi.createdAt),
            amount: Number.parseFloat(hi.amount),
            hostInvoiceLineItemCount: Number.parseInt(hi.hostInvoiceLineItemCount),
            invoiceNumber: hi.invoiceNumber,
          };
        });
        this.host.createdAt = new Date(resp.value.createdAt);
        this.host.lastLoginAt = new Date(resp.value.lastLoginAt);
        this.ready = true;
        this.setBreadCrumb();
      });
    },

    downloadInvoice(date: Date) {
      this.invoiceIdsInUse.push(date);
      AdminUserService.downloadInvoice(this.hostUserId, date.toDateString()).then((res) => {
        var link = document.createElement('a');
        link.innerHTML = 'Download PDF file';
        link.download = 'Rechnung.pdf';
        link.href = 'data:application/octet-stream;base64,' + res.value;
        link.click();
        const index = this.invoiceIdsInUse.indexOf(date);
        this.invoiceIdsInUse.splice(index, 1);
      });
    },
  },
});
